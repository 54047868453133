.el-transfer-panel__filter .el-input__inner[data-v-0dc3e31d] {
  border-radius: 0;
}
.bigdiv[data-v-0dc3e31d] {
  overflow: hidden;
  width: 100%;
}
.bigdiv .leftdiv[data-v-0dc3e31d] {
  float: left;
}
.bigdiv .rightdiv[data-v-0dc3e31d] {
  float: right;
}
.el-table .warning-row[data-v-0dc3e31d] {
  background: green !important;
}
.el-table .success-row[data-v-0dc3e31d] {
  background: red !important;
}
[data-v-0dc3e31d] .el-transfer-panel {
  width: 38%;
}