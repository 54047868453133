.top_btns .left_search[data-v-94caed06] {
  float: left;
  width: 70%;
}
.top_btns .right_btns[data-v-94caed06] {
  float: right;
  width: 30%;
  text-align: right;
}
.weight[data-v-94caed06] {
  font-weight: 600;
}
.bottom_total[data-v-94caed06] {
  background: #eee;
}
.content[data-v-94caed06] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
}
.content span[data-v-94caed06] {
  line-height: 34px;
  font-size: 14px;
}
.content tr[data-v-94caed06] {
  padding: 0;
  margin: 0;
}
.content td[data-v-94caed06] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 34px;
  padding: 0 6px;
  color: #333;
  font-size: 14px;
  text-align: center;
}
.content input[data-v-94caed06] {
  width: 100%;
  line-height: 34px;
  padding: 0 6px;
  font-size: 14px;
}
.bookkeeping-table span[data-v-94caed06] {
  font-size: 14px;
}
.filter-container[data-v-94caed06] {
  margin-bottom: 0 !important;
}
.top_nav[data-v-94caed06] {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}
.amod_ad[data-v-94caed06] {
  animation: ad 0.3s 0s both;
}
.amod_more[data-v-94caed06] {
  animation: more 0.3s 0s both;
}
.amod[data-v-94caed06] {
  height: 30px;
}
.top_table[data-v-94caed06] {
  width: 100%;
  margin: 0 auto;
  background: #f0f2f6;
  height: 180px;
  overflow: hidden;
  text-align: center;
  position: relative;
}
.top_table .more[data-v-94caed06] {
  width: 60px;
  line-height: 30px;
  height: 30px;
  transition: 0.3s;
  color: #666;
  position: absolute;
  bottom: 0;
  right: 20px;
  background: #fff;
  padding: 4px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px 5px 0 0;
  transform: translateX(-50%);
  cursor: pointer;
}
.top_table .status_tag[data-v-94caed06] {
  width: 70px;
  height: 26px;
  line-height: 26px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: #f15a24;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border-radius: 50px;
}
.top_table .common_box[data-v-94caed06] {
  display: inline-block;
  width: 28%;
  height: 100px;
  background: #fff;
  padding: 10px;
  line-height: 80px;
  color: #333;
  position: relative;
  margin: 40px auto;
  cursor: pointer;
}
.top_table h5[data-v-94caed06] {
  float: left;
  font-size: 18px;
  font-weight: normal;
  margin-left: 35%;
}
.top_table .left_product[data-v-94caed06] {
  margin-right: 8%;
}
.top_table .left_product .main_content[data-v-94caed06] {
  width: 100%;
  margin: 0 auto;
}